import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './PortfolioDetailsSolution.module.css'

function PortfolioDetailsSolution({item}) {
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
          <Row>
            <Col lg='6'>
              <img src={item?.second_section_background_image} alt='logo' className={styles['header__logo']}/>
            </Col>
            <Col lg='6'>
              <h1 className={styles['header__title']}>{item?.second_section_title}</h1>
              <p className={styles['header__description']}>{item?.second_section_description}</p>
            </Col>
          </Row>
        </Container>
    </header>
  )
}

export default PortfolioDetailsSolution