import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './SEO.module.css'
import seoImage from 'assets/imgs/seoImage.png'
import seoLocalImage from 'assets/imgs/seoLocalImage.png'
import seoAnalysisImage from 'assets/imgs/seoAnalysisImage.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'
function SEO() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('SEO')}`} description={`${t('Do you want to level up your marketing with animation? Elevate your creative content with the most affordable motion graphics solution on the market.')}`}/>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <img src={seoImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>  
                    <h2 className={styles['seo-info-wrapper__title']}>{t('What is SEO?')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("SEO is short for 'Search Engine Optimization' and it's a crucial part of your overall online strategy. It’s about improving your online visibility by achieving high rankings on Google search results and attract organic search traffic. There are several SEO services you can buy to help you boost your online presence. These include on-page optimization such as keyword research and meta tags, off-page optimization to enhance online authority with quality content and link building, and technical improvements to help your website achieve more prominence in search results.")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className={styles['seo-solution-wrapper']}>
            <Container>
              <h3 className={styles['seo-solution__title']}>{t('Which SEO solution is fit for your business?')}</h3>
              <div className={styles['seo-solution__items-wrapper']}>
                <img src={seoLensImage} alt='seo lens image' className={styles['seo-solution__lens-image']}/>
                <div className={styles['seo-solution__item-wrapper']}>
                  <img src={seoLocalImage} alt='seo local image' className={styles['seo-solution__item-image']}/>
                  <h4 className={styles['seo-solution__item-title']}>{t('Local SEO')}</h4>
                </div>
                <div className={styles['seo-solution__item-wrapper']}>
                  <img src={seoAnalysisImage} alt='seo local image' className={styles['seo-solution__item-image']}/>
                  <h4 className={styles['seo-solution__item-title']}>{t('SEO Analytics')}</h4>
                </div>
                <div className={styles['seo-solution__item-wrapper']}>
                  <img src={seoBackLine} alt='seo local image' className={styles['seo-solution__item-image']}/>
                  <h4 className={styles['seo-solution__item-title']}>{t('BackLinks')}</h4>
                </div>
              </div>
            </Container>
          </section>
        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default SEO