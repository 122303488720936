import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import PortfolioDetailsHeader from 'components/PortfolioDetails/PortfolioDetailsHeader/PortfolioDetailsHeader'
import PortfolioDetailsChallenge from 'components/PortfolioDetails/PortfolioDetailsChallenge/PortfolioDetailsChallenge'
import PortfolioDetailsDesignScreens from 'components/PortfolioDetails/PortfolioDetailsDesignScreens/PortfolioDetailsDesignScreens'
import PortfolioDetailsSolution from 'components/PortfolioDetails/PortfolioDetailsSolution/PortfolioDetailsSolution'
import { useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
function PortfolioDetails() {
  const {t} =useTranslation()
  const selector=useSelector(data=>data.GlobalReducer)
  const [ item ,setItem] =useState({})
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)
  const params = useParams()
  function getItem(){
    axiosConfig.get(`/projects/single-project/${params.id}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
        }
    }).then(res=>{
        setItem(res.data.data)
    }).catch(err=>{
        console.log(err)
    })
}

  useEffect(()=>{
    window.scrollTo(0,0)
    async function promiseAll(){
      await Promise.all([
        getItem(),
      ])
      setIsPageLoading(false)
    }
    promiseAll()
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {isPageLoading&&<Loading/>}
        <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}

        <PortfolioDetailsHeader item={item}/>
        <PortfolioDetailsChallenge item={item}/>
        <PortfolioDetailsSolution item={item}/>
        <PortfolioDetailsDesignScreens item={item}/>

        <Footer/>
    </motion.div>
    </>
  )
}

export default PortfolioDetails