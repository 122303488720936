import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './MotionGraphic.module.css'
import motiongraphicImage from 'assets/imgs/motiongraphicImage.png'
import seoLocalImage from 'assets/imgs/seoLocalImage.png'
import seoAnalysisImage from 'assets/imgs/seoAnalysisImage.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'
function MotionGraphic() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Motion Graphic')}`} description={`${t('Do you want to level up your marketing with animation? Elevate your creative content with the most affordable motion graphics solution on the market.')}`}/>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('Add motion into your media.')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Motion Graphics transforms your static visuals into engaging showstoppers. We can work with your existing assets, or make something from scratch. Let us execute on your creative vision, without the hassle.")}
                    </p>
                  </div>
                </Col>
                <Col lg='6'>
                  <img src={motiongraphicImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
            </Container>
          </section>
          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t('What we Do?')}</h2>
              <Row>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Animated Ads')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('With a maximum of 5 minutes, animated ads are 2D animated assets ideal for digital advertising and social media.')}</p>
                </Col>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Product Animations')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Unlike logo animations or kinetic text, product animations show how your product functions and behaves when customers use it.')}</p>
                </Col>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Animated Logos')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Sometimes referred to as a production logo or vanity card, an animated logo brings your logo to life using original animation styles.')}</p>
                </Col>
              </Row>
            </Container>
          </section>


        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default MotionGraphic