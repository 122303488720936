import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './ManageAllYourAds.module.css'
import motiongraphicImage from 'assets/imgs/motiongraphicImage.png'
import manageAllYourAdsImage1 from 'assets/imgs/manageAllYourAdsImage1.png'
import manageAllYourAdsImage2 from 'assets/imgs/manageAllYourAdsImage2.png'
import seoAnalysisImage from 'assets/imgs/seoAnalysisImage.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'
function ManageAllYourAds() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Manage All your ads')}`} description={`${t('Utilize cutting-edge eCommerce web design to increase sales.')}`}/>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <img src={manageAllYourAdsImage1} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('Google & Microsoft Ads')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Appear on Google & Bing and run search ads like the biggest brands in a matter of minutes. Tired of spending hours on keywords? Let Shown AI generate all the perfect keywords for you!")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('Facebook & Instagram Ads')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Connect with more than 2 billion people. Publish your appealing image or video ads among an engaged audience and reach your advertising goals.")}
                    </p>
                  </div>
                </Col>
                <Col lg='6'>
                  <img src={manageAllYourAdsImage2} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
            </Container>
          </section>

        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default ManageAllYourAds