import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './DigitalMarketing.module.css'
import webDesignConsultayionIcon from 'assets/imgs/webDesignConsultayionIcon.png'
import EcommerceBuildingBG from 'assets/imgs/EcommerceBuildingBG.jpg'
import digitalMarketingArrowImage from 'assets/imgs/digitalMarketingArrowImage.png'
import digitalMarketingGoalImage from 'assets/imgs/digitalMarketingGoalImage.png'
import EcommerceBuildiingImageSectionImage1 from 'assets/imgs/EcommerceBuildiingImageSectionImage1.png'
import EcommerceBuildiingImageSectionImage2 from 'assets/imgs/EcommerceBuildiingImageSectionImage2.png'
import EcommerceBuildiingImageSectionJoinUs from 'assets/imgs/EcommerceBuildiingImageSectionJoinUs.png'
// import webDesignFinalDelivery from 'assets/imgs/webDesignFinalDelivery.png'
import {ReactComponent as BrandIdentitySEOIcon} from 'assets/icons/BrandIdentitySEOIcon.svg'
import {ReactComponent as BrandIdentityPayPerClickIcon} from 'assets/icons/BrandIdentityPayPerClickIcon.svg'
import {ReactComponent as BrandIdentitySocialMediaIcon} from 'assets/icons/BrandIdentitySocialMediaIcon.svg'
import {ReactComponent as BrandIdentityWebsiteDevelopmentIcon} from 'assets/icons/BrandIdentityWebsiteDevelopmentIcon.svg'
import {ReactComponent as BrandIdentityArabicIcon} from 'assets/icons/BrandIdentityArabicIcon.svg'
import {ReactComponent as BrandIdentityReputationIcon} from 'assets/icons/BrandIdentityReputationIcon.svg'
import {ReactComponent as BrandIdentityLinkdedindMarketingIcon} from 'assets/icons/BrandIdentityLinkdedindMarketingIcon.svg'
import {ReactComponent as BrandIdentityBudgetIcon} from 'assets/icons/BrandIdentityBudgetIcon.svg'
import {ReactComponent as BrandIdentityGoalIcon} from 'assets/icons/BrandIdentityGoalIcon.svg'
import {ReactComponent as BrandIdentityAudienceIcon} from 'assets/icons/BrandIdentityAudienceIcon.svg'
import {ReactComponent as BrandIdentityProfessionalIcon} from 'assets/icons/BrandIdentityProfessionalIcon.svg'

import digitalMarketingBG from 'assets/imgs/digitalMarketingBG.png'
import brandIdentityImage2 from 'assets/imgs/brandIdentityImage2.png'
import ecommerceIntegratiyImage from 'assets/imgs/ecommerceIntegratiyImage.png'
import ecommerceNoTimeImage from 'assets/imgs/ecommerceNoTimeImage.png'
import ecommerceWeListenImage from 'assets/imgs/ecommerceWeListenImage.png'
import HeaderWithBG from 'components/Global/Elements/HeaderWithBG/HeaderWithBG'
import { HashLink } from 'react-router-hash-link'
function DigitalMarketing() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          {/* <div className={styles['seo--bg']}></div> */}
          {/* <ServicesTransparentHeader title={`${t('eCommerce Web Design')}`} description={`${t('Utilize cutting-edge eCommerce web design to increase sales.')}`}/> */}

          <HeaderWithBG 
            title={`${t('Digital Marketing')}`} 
            description={`${t('Get powerful marketing strategies and amazing results for your business with our excellent e-marketing services')}`}
            image={digitalMarketingBG}
          />
          <section className={`${styles['seo-info-wrapper3']} mt-5`}>
            {/* <Container> */}
              <Row>
                <Col lg='7' className='order-lg-1 order-2'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title3']}>{t('Pavilion Technology')}</h2>
                    <p className={styles['seo-info-wrapper__description4']}>
                      {t("We make the difference in the world of e-marketing for your business")}
                    </p>
                    <p className={styles['seo-info-wrapper__description5']}>
                      {t("We are an extraordinary agency that stands out for our innovative approach and exceptional experiences. We are proud to be the best in our field and a preferred choice for our customers in Dubai, Abu Dhabi and the UAE.")}
                    </p>
                    <div className={styles['seo-info-wrapper__description6']}>
                      {t('Join Pavilion Technology today and discover how we can make you stand out in the digital marketing market.')}
                    </div>
                  </div>
                </Col>
                <Col lg='5' className='d-flex order-lg-2 order-1'>
                  <img src={brandIdentityImage2} alt='seo image' className={styles['seo-info-wrapper__image3']}/>
                </Col>
              </Row>
              {/* <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink> */}
            {/* </Container> */}
          </section>
          <section className={styles['seo-details-wrapper2']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title2']}>{t('How do I determine the right type of marketing for my business?')}</h2>
                <p className={styles['seo-info-wrapper__description3']}>{t('When you select the right e-marketing service for your business, there are several factors to consider. The most important of these factors are:')}</p>
              <Row>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper2']}`}>
                  <div className={styles['seo-details-wrapper__item2']}>
                    <div className={styles['seo-details-wrapper__item2-icon-wrapper']}>
                      <BrandIdentityBudgetIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item2-info-title']}>{t('Budget')}</h3>
                      <p className={styles['seo-details-wrapper__item2-info-description']}>{t('Determine the budget available to you for e-marketing. This will help you decide which options you can afford.')}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper2']}`}>
                  <div className={styles['seo-details-wrapper__item2']}>
                    <div className={styles['seo-details-wrapper__item2-icon-wrapper']}>
                      <BrandIdentityGoalIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item2-info-title']}>{t('Goals')}</h3>
                      <p className={styles['seo-details-wrapper__item2-info-description']}>{t('Determine the goals you want to achieve through e-marketing. Do you want to increase brand awareness? Or do you aim to increase sales?')}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper2']}`}>
                  <div className={styles['seo-details-wrapper__item2']}>
                    <div className={styles['seo-details-wrapper__item2-icon-wrapper']}>
                      <BrandIdentityAudienceIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item2-info-title']}>{t('Target Audience')}</h3>
                      <p className={styles['seo-details-wrapper__item2-info-description']}>{t("Studying the target audience for your business will help you choose the most effective channels to communicate with them. For example, if your audience is mainly on social media platforms like Facebook, you may prefer to invest in ads on that platform.")}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper2']}`}>
                  <div className={styles['seo-details-wrapper__item2']}>
                    <div className={styles['seo-details-wrapper__item2-icon-wrapper']}>
                      <BrandIdentityProfessionalIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item2-info-title']}>{t('Professional Consultation')}</h3>
                      <p className={styles['seo-details-wrapper__item2-info-description']}>{t('Consult our e-marketing experts. They will be able to provide the advice and guidance needed to choose the right services for your business based on your needs and goals.')}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink> */}
            </Container>
          </section>
          <section className={`${styles['seo-info-wrapper']} mt-5`}>
            <Container>
              <Row>
                <Col lg='5'>
                  <img src={digitalMarketingArrowImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='7'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    {/* <h2 className={styles['seo-info-wrapper__title']}>{t('HAPPY CUSTOMERS, EVERYDAY')}</h2> */}
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("When you choose to work with us at Pavilion Technology, you will get the opportunity to benefit from our extensive experience and knowledge in the field of e-marketing. We will work with you to analyze and assess your needs and provide appropriate options that meet your expectations and achieve your goals")}
                    </p>
                  </div>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>
          

          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t('We work side by side with you to reach your target audience and achieve your success.')}</h2>
                <p className={styles['seo-info-wrapper__description2']}>{t('We are keen to achieve this through')}:</p>
              <Row>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentitySEOIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Search Engine Optimization (SEO):')}</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t('Our dedicated team works hard to achieve exceptional results and lead you in search results, by designing marketing strategies customized to meet your business needs.')}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentityPayPerClickIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Pay-per-click advertising management')}:</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t('We boost your brand visibility, drive the right audience to your site, and optimize your campaigns to ensure you achieve results.')}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentitySocialMediaIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Social Media')}:</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t("We help you connect with potential customers in real and personal ways through social media, while increasing your brand's visibility on these platforms.")}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentityWebsiteDevelopmentIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Website development')}:</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t('Our team designs attractive websites that reflect your brand identity and provide an easy and comfortable browsing experience for visitors, which increases the chances of completing purchases.')}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentityArabicIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Arabic Marketing')}:</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t('We specialize in targeting millions of Arabic speaking users in the GCC, helping you reach this important audience.')}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentityReputationIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Reputation Management')}:</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t("We monitor and manage people's perceptions and online conversations to enhance your brand and personal reputation, ensuring an outstanding online presence.")}</p>
                    </div>
                  </div>
                </Col>
                <Col lg='6' className={`${styles['seo-details-wrapper__col-wrapper']}`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <BrandIdentityLinkdedindMarketingIcon className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <div className={styles['seo-details-wrapper__item-info-wrapper']}>
                      <h3 className={styles['seo-details-wrapper__item-info-title']}>{t('Linkedin Marketing')}:</h3>
                      <p className={styles['seo-details-wrapper__item-info-description']}>{t('We help you reach out and advertise your brand to other businesses on this important social platform.')}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='7'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    {/* <h2 className={styles['seo-info-wrapper__title']}>{t('HAPPY CUSTOMERS, EVERYDAY')}</h2> */}
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("By choosing us as your e-marketing partner, you will get experience and innovative strategies to reach your target audience and achieve amazing results.")}
                    </p>
                  </div>
                </Col>
                <Col lg='5'>
                  <img src={digitalMarketingGoalImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>
        </section>
        
        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default DigitalMarketing