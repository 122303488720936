import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './RequestACallPopUp.module.css'
import {ReactComponent as ModalCloseButton} from 'assets/icons/modalCloseButton.svg'
import modalBadgeImg from 'assets/imgs/modalBadgeImg.png'
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { axiosConfig } from 'utils/axiosConfig';
import { toast } from 'react-toastify';
import ReactLoading from "react-loading";
import EmirateIcon from 'assets/icons/emirateIcon.svg'
import EgyptIcon from 'assets/icons/egyptIcon.svg'
import Kuwaitcon from 'assets/icons/kuwaitcon.svg'
import OmanIcon from 'assets/icons/omanIcon.svg'
import QatarIcon from 'assets/icons/qatarIcon.svg'
import BahrainIcon from 'assets/icons/bahrainIcon.svg'
import IraqIcon from 'assets/icons/iraqIcon.svg'
import KSAIcon from 'assets/icons/KSAIcon.svg'
import { emailPattern } from 'utils/features';
import './RequestACallPopUp.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function RequestACallPopUp({currentCountryCode,setIsSuccessOpen}) {
    const {t} =useTranslation()
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    const countryListRef = useRef(null)
    const modalRef = useRef(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isLoading,setIsLoading]=useState(false)
//   const [name,setName]=useState('')
//   const [email,setEmail]=useState('')
//   const [phone,setPhone]=useState('')
    const selector=useSelector(data=>data.GlobalReducer)
    
    const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
    const [data,setData]=useState({name:'',email:'',phone:''})
    const [errors,setErros]=useState({})
    const [countryCode,setCoutryCode]=useState(+971)
    const [countryIcon,setCoutryIcon]=useState(EmirateIcon)
    const [countries,setCountries]=useState([
        {name:t('Emirates'),code:'+971',flag:EmirateIcon},
        {name:t('Kuwait'),code:'+965',flag:Kuwaitcon},
        {name:t('Egypt'),code:'+20',flag:EgyptIcon},
        {name:t('Oman'),code:'+968',flag:OmanIcon},
        {name:t('Qatar'),code:'+973',flag:QatarIcon},
        {name:t('Bahrain'),code:'+974',flag:BahrainIcon},
        {name:t('Iraq'),code:'+964',flag:IraqIcon},
        {name:t('Saudia Arabia'),code:'+966',flag:KSAIcon},
    ])
    useEffect(()=>{
        setTimeout(()=>{
            handleShow()
        },3000)
    },[])
    useEffect(()=>{
        if (show) {
            document.querySelector('html').classList.add('overflow-hidden')
        } else {
            document.querySelector('html').classList.remove('overflow-hidden')
        }
    },[show])
    function openCountryList(){
        countryListRef.current.classList.remove(styles['contact-us__content-country-list--hidden'])
        document.querySelector('.modal__overflow--hidden').classList.remove(styles['modal__overflow--hidden'])
        
    }
    
    function validateInputs(modififedData){
        let status =true
        let errors ={}
        if(!modififedData?.phone){
            errors = {...errors,phone:t('Phone Is Required')}
            status=false
        }
        if(!emailPattern.test(modififedData?.email)){
            errors = {...errors,email:t('Email Must Be valid Email')}
            status=false
        }
        setErros(errors)
        return status
    }

    function handleChange(value , name){
        let modififedData = {...data}
        modififedData[name] = value
        if(name=='phone' &&isNaN(value)){
            return
        }
        if(isSubmittedBefore){
            validateInputs(modififedData)
        }
        setData(modififedData)
    }

    function changeCountryCode(code,icon){
        setCoutryCode(code)
        setCoutryIcon(icon)
        countryListRef.current.classList.add(styles['contact-us__content-country-list--hidden'])
        document.querySelector('.modal__overflow--hidden').classList.add(styles['modal__overflow--hidden'])
    }

    function sendRequest(){
        setIsSubmittedBefore(true)
        if(!validateInputs(data)){
            return
        }
        let formData = new FormData()
        setIsLoading(true)
        formData.append('name',data?.name)
        formData.append('email',data?.email)
        formData.append('phone',`${countryCode}${data?.phone}`) 
        
        axiosConfig.post('/contact-us/create-contact-us',formData).then(res=>{
            setIsLoading(false)
            setData({name:'',email:'',phone:''})
            setIsSuccessOpen(true)
            handleClose()
            navigate('/success-message')
        }).catch(err=>{
            setIsLoading(false)
            toast.error('Something Went Wrong')
        })
    }
    
    useEffect(()=>{
        setCoutryCode(selector?.currentCountryCode)
        let cout=countries.find(coutry=>{
            return coutry?.code==selector?.currentCountryCode
        })
        if(cout){
            setCoutryCode(cout?.code)
            setCoutryIcon(cout?.flag)
        }
    },[selector])
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size='xl' ref={modalRef} className={`modal__overflow--hidden`}>
        
        <div className={styles['modal__request']}>
            <div className={styles['modal__request-background-wrapper']}>
                <div className={styles['modal__request-background']}></div>
            </div>
            <Container className={styles['modal__request-container']}>
                <button onClick={handleClose} className={styles['modal__close-button']}>
                    {/* <ModalCloseButton className={styles['modal__close-button-icon']}/> */}
                    {t('Close')}
                </button>
                <Row>
                    <Col lg='6'>
                        <img src={modalBadgeImg} className={styles['modal__img']} alt='modal image'/>
                    </Col>
                    <Col lg='6'>
                        <div className={styles['modal__title-wrapper']}>
                            <h1 className={styles['modal__title']}>{t('Get Discount On Your First Order')}</h1>
                            {/* <h2 className={styles['modal__sub-title']}>{t('Your First Order')}</h2> */}
                        </div>
                        <Row>
                            <Col xs='12' className={styles['contact-us__content-input-wrapper']}>
                                <input 
                                    type='text'
                                    className={styles['modal__input']}
                                    placeholder={t('Your Name')}
                                    value={data?.name}
                                    name='name'
                                    onChange={(e)=>{handleChange(e.target.value,e.target.name)}}
                                    />
                            </Col>
                            {/* <Col sm ='6'>
                                <input 
                                    type='text'
                                    className={styles['modal__input']}
                                    placeholder={t('Phone Number')}
                                    value={phone}
                                    onChange={(e)=>{setPhone(e.target.value)}}
                                    />
                            </Col> */}
                            <Col md='6' className={`${styles['contact-us__content-input--phone-wrapper']} ${styles['contact-us__content-input-wrapper']}`}  >
                                    <div className='position-relative'>
                                        <input 
                                        type='text' 
                                        className={`${styles['contact-us__content-input']} ${styles['contact-us__content-input--phone']}`} 
                                        placeholder={t('*********')}
                                        value={data?.phone}
                                        name='phone'
                                        onChange={(e)=>{handleChange(e.target.value,e.target.name)}}
                                        />
                                        <button type='button' className={styles['contact-us__content-country-button']} onClick={openCountryList}>
                                            <img src={countryIcon} className={styles['contact-us__content-phone-icon']}/>{countryCode}
                                        </button>
                                        <div ref={countryListRef} className={`${styles['contact-us__content-country-list']} ${styles['contact-us__content-country-list--hidden']}`}>
                                            {
                                                countries&&countries.map((country,index)=>(
                                                    <button type='button' className={styles['contact-us__content-country-list-button']} onClick={()=>{changeCountryCode(country?.code,country?.flag)}} key={index}>
                                                        <img src={country?.flag} className={styles['contact-us__content-phone-list-icon']}/> {country?.code}
                                                        <span className={styles['contact-us__content-phone-list-span']}>{country?.name}</span>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {errors?.phone&& <p className={styles['contact-us__content-input--error']}>{errors?.phone}</p>}
                                </Col>
                            <Col sm ='6' className={styles['contact-us__content-input-wrapper']}>
                                <input 
                                    type='email'
                                    className={styles['modal__input']}
                                    placeholder={t('Your Email')}
                                    value={data?.email}
                                    name='email'
                                    onChange={(e)=>{handleChange(e.target.value,e.target.name)}}
                                    />
                                    {errors?.email&& <p className={styles['contact-us__content-input--error']}>{errors?.email}</p>}
                            </Col>
                            <Col xs='12'>
                                <button className={styles['modal__send-request']} onClick={sendRequest}>
                                    {
                                        !isLoading?t('Request A Call')
                                        :<ReactLoading type={"spin"} color={'#ffffff'} height={40} width={40} />
                                    }
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        </Modal>
    </>
  )
}

export default RequestACallPopUp