import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './GraphicDesign.module.css'
import seoImage from 'assets/imgs/seoImage.png'
import seoLocalImage from 'assets/imgs/seoLocalImage.png'
import seoAnalysisImage from 'assets/imgs/seoAnalysisImage.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import graphicDesignImage from 'assets/imgs/graphicDesignImage.png'
function GraphicDesign() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Graphics Design')}`} description={`${t('Your brand identity goes beyond a logo and colors. Brand identity is the way you communicate your brand’s value and mission. ')}`}/>

          <section className={styles['seo-solution-wrapper']}>
            <Container>
              <div className={styles['seo-solution__items-wrapper']}>
                <img src={graphicDesignImage} alt='seo lens image' className={styles['seo-solution__lens-image']}/>
                <div className={styles['seo-solution__item-wrapper']}>
                  <h4 className={styles['seo-solution__item-title']}>{t('LOGO DESIGN')}</h4>
                </div>
                <div className={styles['seo-solution__item-wrapper']}>
                  <h4 className={styles['seo-solution__item-title']}>{t('Motion Graphics')}</h4>
                </div>
                <div className={styles['seo-solution__item-wrapper']}>
                  <h4 className={styles['seo-solution__item-title']}>{t('Visual Design')}</h4>
                </div>
              </div>
            </Container>
          </section>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <h2 className={styles['seo-details-wrapper__steps-title']}>{t("In Graphics design we got 4 steps")}</h2>
              <ul className={styles['seo-info__steps-list']}>
                <li className={styles['seo-info__steps-list-item']}>
                  <span className={styles['seo-info__steps-list-item-number']}>1</span>
                  {t('Investigation')}
                </li>
                <li className={styles['seo-info__steps-list-item']}>
                  <span className={styles['seo-info__steps-list-item-number']}>2</span>
                  {t('Brainstorming')}
                </li>
                <li className={styles['seo-info__steps-list-item']}>
                  <span className={styles['seo-info__steps-list-item-number']}>3</span>
                  {t('Design process')}
                </li>
                <li className={styles['seo-info__steps-list-item']}>
                  <span className={styles['seo-info__steps-list-item-number']}>4</span>
                  {t('Polishing up')}
                </li>
              </ul>
            </Container>
          </section>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <h2 className={styles['seo-details-wrapper__title']}>{t("How Do We Dilever")}</h2>
              <p className={styles['seo-details-wrapper__description']}>{t('Sigma Technology cooperates with companies of any scale and offers advanced design solutions. Our design team supports clients at any stage – from the idea to the launch of the product. To ensure that we deliver the outcome that best meets your needs and requirements, we completely immerse ourselves in your business and product.')}</p>
              <ul className={styles['seo-info__list']}>
                <li className={styles['seo-info__list-item']}>
                  {t('Capacity & Competence')}
                </li>
                <li className={styles['seo-info__list-item']}>
                  {t('Consultants')}
                </li>
                <li className={styles['seo-info__list-item']}>
                  {t('Cross-functional Teams')}
                </li>
                <li className={styles['seo-info__list-item']}>
                  {t('Team delivery')}
                </li>
                <li className={styles['seo-info__list-item']}>
                  {t('Nearshore/remote delivery/outsourcing')}
                </li>
              </ul>
            </Container>
          </section>

        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default GraphicDesign