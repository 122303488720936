import React, { useEffect, useState } from 'react'
import styles from './SideBar.module.css'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {ReactComponent as RightArrow} from 'assets/icons/rightArrow.svg'

import logoResponsive from 'assets/imgs/logoResponsive.png'
import {ReactComponent as MessageIconn} from 'assets/icons/messageIcon.svg'
import {ReactComponent as PhoneIconn} from 'assets/icons/phoneIconn.svg'
import {ReactComponent as PinIconn} from 'assets/icons/pinIconn.svg'
import './SideBar.css'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useSelector } from 'react-redux'
import { emailConnect, whtsAppConnect } from 'utils/features'
import { HashLink } from 'react-router-hash-link'
import {ReactComponent as TwitterIconColored} from 'assets/icons/twitterIconColored.svg'
import {ReactComponent as InstaColoredIcon} from 'assets/icons/instaColoredIcon.svg'
import {ReactComponent as FaceBookColoredIcon} from 'assets/icons/faceBookColoredIcon.svg'
import {ReactComponent as LinkedInColoredIcon} from 'assets/icons/linkedInColoredIcon.svg'
import {ReactComponent as TiktokColoredIcon} from 'assets/icons/tiktokColoredIcon.svg'
import {ReactComponent as BehanceDarkIcon} from 'assets/icons/behanceDarkIcon.svg'

import InstaColoredIconSrc from 'assets/icons/instaColoredIcon.svg'
import TwitterIconColoredSrc from 'assets/icons/twitterIconColored.svg'
import FaceBookColoredIconSrc from 'assets/icons/faceBookColoredIcon.svg'
import LinkedInColoredIconSrc from 'assets/icons/linkedInColoredIcon.svg'
import TiktokColoredIconSrc from 'assets/icons/tiktokColoredIcon.svg'
import BehanceDarkIconSrc from 'assets/icons/behanceDarkIcon.svg'

import {ReactComponent as Google} from 'assets/icons/google.svg'
import {ReactComponent as StarIcon} from 'assets/icons/starIcon.svg'
// import {ReactComponent as MessageIconn} from 'assets/icons/messageIcon.svg'
// import {ReactComponent as PhoneIconn} from 'assets/icons/phoneIconn.svg'
// import {ReactComponent as PinIconn} from 'assets/icons/pinIconn.svg'
import {ReactComponent as FacebookIcon} from 'assets/icons/facebookIcon.svg'
import {ReactComponent as InstgramIcon} from 'assets/icons/instgramIcon.svg'
import {ReactComponent as LinkedInIcon} from 'assets/icons/linkedInIcon.svg'
function SideBar({isSideBarVisible,toggleSideNavBar,brands,types}) {
    const {t} =useTranslation()
    const [isbrandSectionActive,setIsbrandSectionActive]=useState(false)
    const [isTypeSectionActive,setIsTypeSectionActive]=useState(false)
    const navigate =useNavigate()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
    useEffect(()=>{
        toggleSideNavBar()
    },[navigate])
    return (
    <nav className={`${styles["sidebar"]} ${isSideBarVisible && styles['sidebar--open']}`}>
        <div className={styles["sidebar__content"]}>
            <div className='d-flex justify-content-between mb-3'>
                <div className={styles["sidebar__content-language"]}>
                    <ChangeLangueList/>
                </div>
                {/* <NavLink to='/' ><PavilionSmallLogo className={styles['sidebar__logo']}/></NavLink> */}
                <button role='button' onClick={()=>{toggleSideNavBar('close')}} className={styles['sidebar__close-button']}>X</button>
                {/* <button role='button' onClick={()=>{toggleSideNavBar('close')}} className={styles['sidebar__close-button']}><RightArrow className={styles['sidebar__close-icon']}/></button> */}
            </div>
            <ul className={styles["sidebar__nav-list"]}>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <NavLink to='/' className={styles["sidebar__nav-link"]}>                    
                        <img src={logoResponsive} alt='logo' className={`${styles['sidebar__logo']}`}/>
                    </NavLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/' className={styles["sidebar__nav-link"]}>{t('Home')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#about-us' className={styles["sidebar__nav-link"]}>{t('About Us')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#services' className={styles["sidebar__nav-link"]}>{t('Services')}</HashLink>
                </li>
                {/* <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#portfolio' className={styles["sidebar__nav-link"]}>{t('Portfolio')}</HashLink>
                </li> */}
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <a href={'https://blog.pavilion-teck.com/index.php/our-blog/'} target='_blank'  className={styles["sidebar__nav-link"]}>{t('Blogs')}</a>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#contact-us' className={styles["sidebar__nav-link"]}>{t('Contact Us')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <div className={styles['footer__social-icons-wrapper']}>
                        {
                            settings?.twitter_link&&
                            <a href={`${settings?.twitter_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}>
                                <img src={TwitterIconColoredSrc} alt='social logo' className={styles['footer__social-icon']}/>
                                {/* <TwitterIconColored className={styles['footer__social-icon']}/> */}
                            </a>
                        }
                        {
                            settings?.instagram_link&&
                            <a href={`${settings?.instagram_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}>
                                <img src={InstaColoredIconSrc} alt='social logo' className={styles['footer__social-icon']}/>
                                {/* <InstaColoredIcon className={styles['footer__social-icon']}/> */}
                            </a>
                        }
                        {
                            settings?.facebook_link&&
                            <a href={`${settings?.facebook_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}>
                                <img src={FaceBookColoredIconSrc} alt='social logo' className={styles['footer__social-icon']}/>
                                {/* <FaceBookColoredIcon className={styles['footer__social-icon']}/> */}
                            </a>
                        }
                        {
                            settings?.behance_link&&
                            <a href={`${settings?.behance_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}>
                                <img src={BehanceDarkIconSrc} alt='social logo' className={styles['footer__social-icon']}/>
                                {/* <BehanceDarkIcon className={styles['footer__social-icon']}/> */}
                            </a>
                        }
                        {
                            settings?.linkedin_link&&
                            <a href={`${settings?.linkedin_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}>
                                <img src={LinkedInColoredIconSrc} alt='social logo' className={styles['footer__social-icon']}/>
                                {/* <LinkedInColoredIcon className={styles['footer__social-icon']}/> */}
                            </a>
                        }
                        {
                            settings?.tiktok_link&&
                            <a href={`${settings?.tiktok_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}>
                                <img src={TiktokColoredIconSrc} alt='social logo' className={styles['footer__social-icon']}/>
                                {/* <TiktokColoredIcon className={styles['footer__social-icon']}/> */}
                            </a>
                        }
                    </div>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <a href={"https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai"} target='_blank' className={styles['sidebar__contact-item']}>
                        <PinIconn/>
                        <span className={styles['sidebar__contact-text']}>{settings?.location?settings?.location:'1606 The Metropolis Tower - Business Bay- Dubai'}</span>
                    </a>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'00971542172270'}`, '_self')}} className={styles['sidebar__contact-item']}>
                        <PhoneIconn/>
                        <span className={styles['sidebar__contact-text']}>{settings?.contact_phone_number?settings?.contact_phone_number:'00971542172270'}</span>
                    </button>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <a href={emailConnect(settings?.contact_email?settings?.contact_email:'Info@pavilion-teck.com')} className={styles['sidebar__contact-item']}>
                        <MessageIconn/>
                        <span className={styles['sidebar__contact-text']}>{settings?.contact_email?settings?.contact_email:'Info@pavilion-teck.com'}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div className={styles["sidebar__overlayer"]} onClick={()=>{toggleSideNavBar('close')}}></div>
    </nav>
  )
}

export default SideBar